<template>
    <b-card-actions ref="cardWareHouseTable" title="Estadísticas de Rutas por almacen (Histórico)" action-refresh @refresh="getStatistics" :isTable="true">
        <b-row>
            <b-col cols="12">
                <b-table :items="statistics" :fields="fields" responsive hover class="mb-1_ _text-small _small _b-table-small" small>
                    <template v-slot:cell(almacen)="data">                    
                        <span>{{ data.item.almacen }}</span>
                    </template>
                    <template v-slot:cell(total_Abiertas)="data">
                        <span class="text-center">{{ kFormatter(data.item.total_Abiertas) }}</span>
                    </template>

                    <template v-slot:cell(total_Canceladas)="data">
                        <span class="text-center">{{ kFormatter(data.item.total_Canceladas) }}</span>
                    </template>

                    <template v-slot:cell(total_Cerradas)="data">
                        <span class="text-center">{{ kFormatter(data.item.total_Cerradas) }}</span>
                    </template>

                    <template v-slot:cell(total_Cliente_Pasa)="data">
                        <span class="text-center">{{ kFormatter(data.item.total_Cliente_Pasa) }}</span>
                    </template>

                    <template v-slot:cell(total_Ruta)="data">
                        <span class="text-center">{{ kFormatter(data.item.total_Ruta) }}</span>
                    </template>

                    <template v-slot:cell(total_Salida_Ticket)="data">
                        <span class="text-center">{{ kFormatter(data.item.total_Salida_Ticket) }}</span>
                    </template>
                </b-table>
            </b-col>
        </b-row>       
    </b-card-actions>
</template>

<script>
import {
    BRow, BCol,
    BCard, BTable, BAvatar, BImg, BOverlay, BButton, BCardBody, BCardHeader, BCardTitle
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()

export default {
    name: 'WareHouseTable',
    components: {
        BRow, BCol,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BOverlay,
        BButton, 
        BCardBody, 
        BCardHeader, 
        BCardTitle,
        BCardActions,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            loading: false,
            fields: [
                { key: 'almacen', label: '', },
                { key: 'total_Abiertas', label: 'ABIERTAS', thClass: 'text-center', tdClass: 'text-center' },
                { key: 'total_Canceladas', label: 'CANCELADAS', thClass: 'text-center', tdClass: 'text-center' },
                { key: 'total_Cerradas', label: 'CERRADAS' , thClass: 'text-center', tdClass: 'text-center'},
                { key: 'total_Cliente_Pasa', label: 'CLIENTE PASA', thClass: 'text-center', tdClass: 'text-center' },
                { key: 'total_Ruta', label: 'EN RUTA', thClass: 'text-center', tdClass: 'text-center' },
                { key: 'total_Salida_Ticket', label: 'SALIDAS TICKET', thClass: 'text-center', tdClass: 'text-center' },
            ],
            statistics: []
        }
    },
    computed: {
        isReaload() {
            return store.getters['routing/getIsRealodAbierta'] || store.getters['routing/getIsRealodEnRuta'] ||
                store.getters['routing/getIsRealoadCerrada'] || store.getters['routing/getIsRealodClientePasa']
        }
    },
    async mounted() {
        await this.getStatistics()
    },
    methods: {
        kFormatter,
        async getStatistics () {
            // Esperar a que Vue actualice el DOM
            await this.$nextTick()

            this.$refs['cardWareHouseTable'].showLoading = true

            try {
                const { data } = await DashboardsResource.getStatisticRoutingByCurrentMonthByWareHouse()
                this.statistics = data.data
            } catch (error) {
                console.log(error)
            } finally {
                this.$refs['cardWareHouseTable'].showLoading = false
            }
        }
    },
    watch: {
        isReaload() {
            this.getStatistics()
        }
    }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>

<style>
.b-table-sticky-header {
    overflow-y: auto;
    max-height: 350px!important;
}
</style>