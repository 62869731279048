<template>
    <b-card-actions ref="cardStatistics" title="Estadísticas de Rutas" :sub-title="`Actualizado al mes actual ${currentDate('MMMM')}`" action-refresh @refresh="getStatistics">
        <b-row>
            <b-col v-for="item in statistics" :key="item.icon" xl="2" sm="6">
                <b-media no-body>
                    <b-media-aside class="mr-2">
                        <b-avatar size="48" :variant="item.color">
                            <feather-icon size="24" :icon="item.icon" />
                        </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                        <h4 class="font-weight-bolder mb-0">
                            {{ kFormatter(item.title) }}
                        </h4>
                        <b-card-text class="font-small-2 mb-0">
                            {{ item.subtitle }}
                        </b-card-text>
                    </b-media-body>
                </b-media>
            </b-col>
        </b-row>
    </b-card-actions>
</template>

<script>
import {
    BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,BLink,
    BOverlay, BButton
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import _ from 'lodash'
import { kFormatter } from '@core/utils/filter'
import store from '@/store'
import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()

export default {
    name: 'Statistics',
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardTitle,
        BCardText,
        BCardBody,
        BMedia,
        BAvatar,
        BMediaAside,
        BMediaBody,
        BOverlay,
        BLink,
        BButton,

        //
        BCardActions,
    },
    data() {
        return {
            statistics: [],
            loading: false,
        }
    },
    computed: {
        isReaload() {
            return store.getters['routing/getIsRealodAbierta'] || store.getters['routing/getIsRealodEnRuta'] ||
                store.getters['routing/getIsRealoadCerrada'] || store.getters['routing/getIsRealodClientePasa']
        }
    },
    mounted() {
        this.getStatistics()
    },
    methods: {
        kFormatter,
        async getStatistics() {
            
            // Esperar a que Vue actualice el DOM
            await this.$nextTick()

            this.$refs['cardStatistics'].showLoading = true
            try {
                const { data } = await DashboardsResource.getStatisticRoutingByCurrentMonth()
                if (data.isSuccesful) {
                    this.statistics = _.orderBy(data.data, ['subtitle'], ['asc'])
                }
            } catch (error) {
                this.$refs['cardStatistics'].showLoading = false
                console.log(error)
            }finally {
                this.$refs['cardStatistics'].showLoading = false
            }
        },
    },
    watch: {
        isReaload() {
            this.getStatistics()
        }
    }

}
</script>