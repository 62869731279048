<template>
    <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
        <b-card v-if="medal" class="card-congratulation-medal">
            <h6>🎉 {{ medal.nombreChofer ? medal.nombreChofer : 'No hay chofer' }}!</h6>
            <b-card-text class="font-small-3">
                Con más rutas asignadas
                del mes actual <strong class="text-capitalize">{{ currentDate('MMMM') }}</strong>.
            </b-card-text>
            <h3 class="mb-75 mt-2_ _pt-50">
                <b-link>{{ kFormatter(medal.totalMesActual ? medal.totalMesActual : 0) }}</b-link>
            </h3>
            <p class="card-text text-muted font-small-2">
                <span class="fw-bolder">{{ kFormatter(medal.totalMesAnterior ? medal.totalMesAnterior : 0) }}</span><span> Mes anterior</span>
            </p>
            <b-img v-if="medal.totalMesActual" :src="require('@/assets/images/illustration/badge.svg')" class="congratulation-medal"
                alt="Medal Pic" />
        </b-card>
    </b-overlay>
</template>

<script>
import {
    BCard, BCardText, BLink, BButton, BImg, BOverlay, BSpinner,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import DashboardsProvider from '@/providers/Dashboards'
const DashboardsResource = new DashboardsProvider()

export default {
    name:'Medal',
    components: {
        BCard,
        BCardText,
        BLink,
        BImg,
        BButton,
        BOverlay,
        BSpinner,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            loading: false,
            medal: {
                nombreChofer: '',
                totalMesActual: 0,
                totalMesAnterior: 0,
            },
        }
    },
    computed: {
        isReaload() {
            return store.getters['routing/getIsRealodAbierta'] || store.getters['routing/getIsRealodEnRuta'] ||
                store.getters['routing/getIsRealoadCerrada'] || store.getters['routing/getIsRealodClientePasa']
        }
    },
    async mounted() {
        await this.getMedal()
    },
    methods: {
        kFormatter,
        async getMedal() {
            this.loading = true
			try {
				const { data } = await DashboardsResource.getTopChofer()
				if (data.isSuccesful) {
					this.medal = {...data.data}
				}
			} catch (error) {
				console.log(error)
			} finally {
				this.loading = false
			}
        }
    },
    watch: {
        isReaload() {
            this.getMedal()
        }
    }
}
</script>