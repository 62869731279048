<template>
  <div>

    <b-row v-if="isAdministrator">
      <b-col cols="12">    
        <dashboard-administrator />    
      </b-col>
    </b-row>

    
    
    <b-card v-else title="Welcome to Ferreysa">
      <b-card-text>
        Ferreysa is a web application that allows you to manage the delivery routes of your company.
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BLink, BImg, BRow, BCol, } from 'bootstrap-vue'
import store from '@/store'
import DashboardAdministrator from './DashboardAdministrator.vue'
import DeliveryRoutesProvider from '@/providers/DeliveryRoutes'
const DeliveryRoutesResource = new DeliveryRoutesProvider()

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BRow,
    BCol,

    //
    DashboardAdministrator,
  },
  data() {
    return {
      title: 'Home',
      logo: require('@/assets/images/logo/ferreysa.png')
    }
  },
  computed: {
    isAdministrator () {
      return store.getters['auth/getUser'].customRol.id == store.getters['auth/getAdministratorRol']
    },
  },  
}
</script>

<style>

</style>
