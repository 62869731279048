import axios from '@/libs/axios'

export default class Dashboards {
    
    getStatisticRoutingByCurrentMonth() {
        return axios.get(`/v2/Dashboard/quantities-by-current-month`)
    }

    getTopChofer() {
        return axios.get(`/v2/Dashboard/top-chofer`)
    }

    getBinnacles() {
        return axios.get(`/v2/Dashboard/binnacle`)
    }

    getStatisticRoutingByCurrentMonthByWareHouse() {
        return axios.get(`/v2/Dashboard/quantities-by-current-month-by-almacen`)
    }

    getStatisticRoutingByOperators() {
        return axios.get(`/v2/Dashboard/quantities-choferes`)
    }

}